<template>
  <div class="container terms-of-service-container m-b-110 pt-terms-title">
    <div class="card p-1 text-first border-0">
      <div class="card-body mx-auto">
        <div v-if="$i18n.locale ==='en'" class="terms-en row update-btn-div">
          <h2 class="mt-0 mt-sm-4 mb-4 fw-bold font-30">Terms of Service</h2>
          <div class="col">
            <p>
              These terms of service (hereinafter referred to as the “Service”) shall stipulate the terms for the use of
              HeaLo, a health information management system (hereinafter referred to as the “System”) offered by Public
              Interest Incorporated Foundation Japan Tennis Association (hereinafter referred to as “JTA”). The users
              registered on this System (hereinafter referred to as the “Users”) shall use this System under the
              Service.
            </p>
            <div class="row">
              <h3>Article 1 (Application)</h3>
              <ol class="li-number">
                <li>The Service shall apply to all connections in using the System between the User and JTA.
                </li>
                <li>Regarding this System, JTA may stipulate rules, etc., and various regulations (hereinafter
                  referred to as “Individual Regulations”) other than these Services. These Individual Regulations,
                  regardless of its name, shall be part of the Service.
                </li>
                <li>In case the regulations of this Service contradict the regulations in the Individual Regulations,
                  the regulations in the Individual Regulations shall take precedence unless otherwise specified in
                  the Individual Regulations.
                </li>
              </ol>
            </div>
            <div class="row">
              <h3>Article 2 (Usage Registration)</h3>
            </div>
            <div class="row">
              <ol class="li-number">
                <li value="4">
                  Under the agreement with the parties who are interested in registering to this Service, the parties
                  shall apply through the user registration procedures as stipulated by JTA and the registration shall
                  be deemed completed when JTA approves the registration.
                </li>

                <li>JTA may not approve the application of the user registration when the applicant falls under any of
                  the following reasons and JTA shall not be held responsible to disclose the reasons.
                  <ol class="li-latin">
                    <li>When fraudulent items are found in the user registration application
                    </li>
                    <li>When the application is from a party who is found to have infringed the Services
                    </li>
                    <li>When JTA determines that the user registration is inappropriate or for other reasons
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
            <div class="row">
              <h3>Article 3 (Management of User ID and Password)</h3>
            </div>
            <div class="row">
              <ol class="li-number">
                <li value="6">
                  The Users shall, under their own responsibility, appropriately manage their email address and
                  password under this System.
                </li>

                <li>In whatever circumstances, the Users shall not assign, transfer, lend or share their email address
                  or password to a third party. When any party who logins to the System with the email address and
                  password that coincides with the registered information, JTA shall determine that they are the Users
                  themselves who are using the System.

                </li>
                <li>JTA shall not be responsible for any damages whatsoever occurring from the use of email addresses
                  and passwords by a third party unless the damages are due to intentional or gross negligence by JTA.
                </li>
              </ol>
            </div>
            <div class="row">
              <h3>Article 4 (Prohibited Matters)</h3>
            </div>
            <div class="row">
              <ol class="li-number">
                <li value="9">
                  Users are prohibited to perform the following acts.

                <li>Acts that violate laws or public order and morals

                </li>
                <li>Acts that are unlawful and criminal

                </li>
                <li>Acts that infringe copyrights, trademark rights and other intellectual property rights included in
                  this System, such as the contents of this System

                </li>
                <li>Acts that destroy or interfere with the functions of servers or networks of JTA, other users, or
                  other third parties

                </li>
                <li>Acts that commercially use information obtained from this System

                </li>
                <li>Acts that may interfere with the operation of JTA services

                </li>
                <li>Unauthorized access or attempting to access illegally

                </li>
                <li>Acts of collecting or accumulating personal information, etc. of other Users

                </li>
                <li>Acts of using this System for improper purposes

                </li>
                <li>Acts that cause disadvantage, damage, or discomfort to other Users of this System or other third
                  parties

                </li>
                <li>Acts of impersonating other Users

                </li>
                <li>Advertising, promotion, solicitation, or business activities on this System that is not permitted
                  by JTA

                </li>
                <li>Acts that directly or indirectly benefit antisocial forces in connection with the Services of JTA

                </li>
                <li>Other acts that JTA deems to be inappropriate</li>
              </ol>
            </div>
            <div class="row">
              <h3>Article 5 (Termination of System)</h3>
            </div>
            <div class="row">
              <ol class="li-number">
                <li value="23">
                  In case JTA determines that any of the following reasons apply, JTA may terminate or discontinue
                  part of the System without notifying the User in advance.
                  <ol class="li-latin">

                    <li>When performing maintenance or updating the computer system in this System

                    </li>
                    <li>When it is difficult to provide this System due to force majeure such as earthquakes,
                      lightning strikes, fire, blackouts, or natural disasters, etc.

                    </li>
                    <li>Termination due to accidents to the computer or communication circuits</li>
                    <li>Otherwise, when JTA determines that it is difficult to provide this System</li>
                  </ol>
                </li>
                <li>JTA shall not be responsible for any disadvantages or damages to the User or third parties due to
                  the termination or discontinuation of this System.
                </li>
              </ol>
            </div>
            <div class="row">
              <h3>Article 6 (Usage Limit and Deregistration)</h3>
            </div>
            <div class="row">
              <ol class="li-number">
                <li value="25">
                  JTA shall, without advance notice, limit all or part of the use of this System or may delete the
                  registration of the User when the User applies to any of the following.
                  <ol class="li-latin">

                    <li>When infringing any of the provisions in this Service

                    </li>
                    <li>When it is determined that the registration items are false

                    </li>
                    <li>When there is no response for a certain period after contact made by JTA

                    </li>
                    <li>When there is no use for a certain period after the last usage of this System
                    </li>
                    <li>Otherwise, when JTA determines that the use of this System is inappropriate</li>
                  </ol>
                </li>
                <li>JTA shall not be responsible for any damages to the User due to the actions by JTA based on this
                  provision.
                </li>
              </ol>
            </div>
            <div class="row">
              <h3>Article 7 (Withdrawal)</h3>
            </div>
            <div class="row">
              <div class="col">
                The User shall be able to withdraw from this System by taking the resignation procedures stipulated by
                JTA.
              </div>
            </div>
            <div class="row mt-2">
              <h3>Article 8 (Disclaimer of Warranty)</h3>
            </div>
            <div class="row">
              <ol class="li-number">
                <li value="27">
                  JTA shall not explicitly or imply any warranty for any actual or legal defects (including defects,
                  errors and bugs, infringements, etc., to safety, reliability, accuracy, integrity, validity,
                  compatibility to a specific purpose, security, etc.) to this System.
                </li>

                <li>JTA shall not be responsible for any damages to the User under this System. However, in case the
                  contract between JTA and the User regarding this System (including this Service) is a consumer
                  contract stipulated under the Consumer Contract Act, this disclaimer shall not be applied.

                </li>
                <li>Regardless of the previous provisory clause, JTA shall not be responsible for any damages cause by
                  special circumstances (including damages that were foreseen or could be foreseen by JTA or the User)
                  to the User due to ay negligence such as default or wrongdoing by JTA (excluding serious
                  negligence).
                </li>
              </ol>
            </div>
            <div class="row">
              <h3>Article 9 (Change in Service Content)</h3>
            </div>
            <div class="row">
              <div class="col">
                JTA shall, without advance notice to the User, be able to change the content of the Service or suspend
                the Services and shall not be responsible to any damages caused to the User.
              </div>
            </div>
            <div class="row mt-2">
              <h3>Article 10 (Change in User Regulations)</h3>
            </div>
            <div class="row">
              <div class="col">
                JTA shall, when it is determined necessary, be able to change the Services without notifying the User
                in advance. Moreover, after the change of this Service, at the start of the System, it shall be deemed
                that the said User agreed to the Services after the changes.
              </div>
            </div>
            <div class="row mt-2">
              <h3>Article 11 (Handling Personal Information)</h3>
            </div>
            <div class="row">
              <div class="col">
                JTA shall appropriately handle personal information obtained through the use of this System under the
                Privacy Policies of JTA.
              </div>
            </div>
            <div class="row mt-2">
              <h3>Article 12 (Notice or Contact)</h3>
            </div>
            <div class="row">
              <div class="col">
                Any notification or contact between JTA and the User shall be carried out by the procedures stipulated
                by JTA. JTA shall notify or contact the User to the contact address that is currently registered and
                considered valid and shall consider that the User received the notices when dispatched as long as
                there is no notification of change according to the procedures stipulated separately by JTA.
              </div>
            </div>
            <div class="row mt-2">
              <h3>Article 13 (Prohibition of Transfer of Rights and Obligations)</h3>
            </div>
            <div class="row">
              <div class="col">

                The User shall not be able to transfer rights or obligations or provide as collateral to a third
                party under the status of the user contract or based on this Service.
              </div>
            </div>
            <div class="row mt-2">
              <h3>Article 14 (Governing Laws and Jurisdiction)</h3>
            </div>
            <div class="row">
              <ol class="li-number">
                <li value="30">
                  The interpretation of this Service shall be governed by the laws of Japan.
                </li>
                <li>Any disputes arising from this System shall be subject to the exclusive jurisdiction of the
                  court governing the location of the headquarters of JTA.
                </li>
              </ol>
            </div>
            <div class="row">
              <div class="col eol">End</div>
            </div>

          </div>
        </div>
        <div v-if="$i18n.locale ==='ja'" class="terms-ja row update-btn-div">
          <h2 class="mt-0 mt-sm-4 mb-4 fw-bold font-30">利用規約</h2>
          <div class="col">
            <p>
              この利用規約（以下，「本規約」といいます。）は，公益財団法人日本テニス協会（以下，「本協会」といいます。）が本健康情報管理システムHeaLo（以下，「本システム」といいます。）の利用条件を定めるものです。登録ユーザーの皆さま（以下，「ユーザー」といいます。）には，本規約に従って，本システムをご利用いただきます。
            </p>
            <div class="row">
              <h3>第1条（適用)</h3>
            </div>
            <div class="row">
              <ol class="li-number">
                <li>本規約は，ユーザーと本協会との間の本システムの利用に関わる一切の関係に適用されるものとします。</li>
                <li>
                  本協会は本システムに関し，本規約のほか，ご利用にあたってのルール等，各種の定め（以下，「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず，本規約の一部を構成するものとします。
                </li>
                <li>本規約の規定が前条の個別規定の規定と矛盾する場合には，個別規定において特段の定めなき限り，個別規定の規定が優先されるものとします。</li>
              </ol>
            </div>
            <div class="row">
              <h3>第2条（利用登録）</h3>
            </div>
            <div class="row">
              <ol class="li-number">
                <li value="4">本システムにおいては，登録希望者が本規約に同意の上，本協会の定める方法によって利用登録を申請し，本協会がこれを承認することによって，利用登録が完了するものとします。
                </li>
                <li>本協会は，利用登録の申請者に以下の事由があると判断した場合，利用登録の申請を承認しないことがあり，その理由については一切の開示義務を負わないものとします。
                  <ol class="li-latin">
                    <li>利用登録の申請に際して虚偽の事項を届け出た場合</li>
                    <li>本規約に違反したことがある者からの申請である場合</li>
                    <li>その他，本協会が利用登録を相当でないと判断した場合</li>
                  </ol>
                </li>
              </ol>
            </div>
            <div class="row">
              <h3>第3条（ユーザーIDおよびパスワードの管理）</h3>
            </div>
            <div class="row">
              <ol class="li-number">
                <li value="6">ユーザーは，自己の責任において，本システムのメールアドレスおよびパスワードを適切に管理するものとします。</li>
                <li>
                  ユーザーは，いかなる場合にも，メールアドレスおよびパスワードを第三者に譲渡または貸与し，もしくは第三者と共用することはできません。本協会は，メールアドレスとパスワードの組み合わせが登録情報と一致してログインされた場合には，そのメールアドレスを登録しているユーザー自身による利用とみなします。
                </li>
                <li>メールアドレス及びパスワードが第三者によって使用されたことによって生じた損害は，本協会に故意又は重大な過失がある場合を除き，本協会は一切の責任を負わないものとします。</li>
              </ol>
            </div>
            <div class="row">
              <h3>第4条（禁止事項）</h3>
            </div>
            <div class="row">
              <div class="col">ユーザーは，本システムの利用にあたり，以下の行為をしてはなりません。</div>
              <ol class="li-number">
                <li value="9">法令または公序良俗に違反する行為</li>
                <li>犯罪行為に関連する行為</li>
                <li>本システムの内容等，本システムに含まれる著作権，商標権ほか知的財産権を侵害する行為</li>
                <li>本協会，ほかのユーザー，またはその他第三者のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為</li>
                <li>本システムによって得られた情報を商業的に利用する行為</li>
                <li>本協会のサービスの運営を妨害するおそれのある行為</li>
                <li>不正アクセスをし，またはこれを試みる行為</li>
                <li>他のユーザーに関する個人情報等を収集または蓄積する行為</li>
                <li>不正な目的を持って本システムを利用する行為</li>
                <li>本システムの他のユーザーまたはその他の第三者に不利益，損害，不快感を与える行為</li>
                <li>他のユーザーに成りすます行為</li>
                <li>本協会が許諾しない本システム上での宣伝，広告，勧誘，または営業行為</li>
                <li>本協会のサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為</li>
                <li>その他，本協会が不適切と判断する行為</li>
              </ol>
            </div>
            <div class="row">
              <h3>第5条（本システムの提供の停止等）</h3>
            </div>
            <div class="row">
              <ol class="li-number">
                <li value="23">
                  本協会は，以下のいずれかの事由があると判断した場合，ユーザーに事前に通知することなく本システムの全部または一部の提供を停止または中断することができるものとします。
                  <ol class="li-latin">
                    <li>本システムにかかるコンピュータシステムの保守点検または更新を行う場合</li>
                    <li>地震，落雷，火災，停電または天災などの不可抗力により，本システムの提供が困難となった場合</li>
                    <li>コンピュータまたは通信回線等が事故により停止した場合</li>
                    <li>その他，本協会が本システムの提供が困難と判断した場合</li>
                  </ol>
                </li>
                <li>本協会は，本システムの提供の停止または中断により，ユーザーまたは第三者が被ったいかなる不利益または損害についても，一切の責任を負わないものとします。</li>
              </ol>
            </div>
            <div class="row">
              <h3>第6条（利用制限および登録抹消）</h3>
            </div>
            <div class="row">
              <ol class="li-number">
                <li value="25">
                  本協会は，ユーザーが以下のいずれかに該当する場合には，事前の通知なく，ユーザーに対して，本システムの全部もしくは一部の利用を制限し，またはユーザーとしての登録を抹消することができるものとします。
                  <ol class="li-latin">
                    <li>本規約のいずれかの条項に違反した場合</li>
                    <li>登録事項に虚偽の事実があることが判明した場合</li>
                    <li>本協会からの連絡に対し，一定期間返答がない場合</li>
                    <li>本システムについて，最終の利用から一定期間利用がない場合</li>
                    <li>その他，本協会が本システムの利用を適当でないと判断した場合</li>
                  </ol>
                </li>
                <li>本協会は，本条に基づき本協会が行った行為によりユーザーに生じた損害について，一切の責任を負いません。</li>
              </ol>
            </div>
            <div class="row">
              <h3>第7条（退会）</h3>
            </div>
            <div class="row">
              <div class="col">ユーザーは，本協会の定める退会手続により，本システムから退会できるものとします。</div>
            </div>
            <div class="row mt-2">
              <h3>第8条（保証の否認および免責事項）</h3>
            </div>
            <div class="row">
              <ol class="li-number">
                <li value="27">
                  本協会は，本システムに事実上または法律上の瑕疵（安全性，信頼性，正確性，完全性，有効性，特定の目的への適合性，セキュリティなどに関する欠陥，エラーやバグ，権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。
                </li>
                <li>
                  本協会は，本システムに起因してユーザーに生じたあらゆる損害について一切の責任を負いません。ただし，本システムに関する本協会とユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合，この免責規定は適用されません。
                </li>
                <li>
                  前項ただし書に定める場合であっても，本協会は，本協会の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（本協会またはユーザーが損害発生につき予見し，または予見し得た場合を含みます。）について一切の責任を負いません。
                </li>
              </ol>
            </div>
            <div class="row">
              <h3>第9条（サービス内容の変更等）</h3>
            </div>
            <div class="row">
              <div class="col">
                本協会は，ユーザーに通知することなく，本システムの内容を変更しまたは本システムの提供を中止することができるものとし，これによってユーザーに生じた損害について一切の責任を負いません。
              </div>
            </div>
            <div class="row mt-2">
              <h3>第10条（利用規約の変更）</h3>
            </div>
            <div class="row">
              <div class="col">
                本協会は，必要と判断した場合には，ユーザーに通知することなくいつでも本規約を変更することができるものとします。なお，本規約の変更後，本システムの利用を開始した場合には，当該ユーザーは変更後の規約に同意したものとみなします。
              </div>
            </div>
            <div class="row mt-2">
              <h3>第11条（個人情報の取扱い）</h3>
            </div>
            <div class="row">
              <div class="col">本協会は，本システムの利用によって取得する個人情報については，本協会「プライバシーポリシー」に従い適切に取り扱うものとします。</div>
            </div>
            <div class="row mt-2">
              <h3>第12条（通知または連絡）</h3>
            </div>
            <div class="row">
              <div class="col">
                ユーザーと本協会との間の通知または連絡は，本協会の定める方法によって行うものとします。本協会は,ユーザーから,本協会が別途定める方式に従った変更届け出がない限り,現在登録されている連絡先が有効なものとみなして当該連絡先へ通知または連絡を行い,これらは,発信時にユーザーへ到達したものとみなします。
              </div>
            </div>
            <div class="row mt-2">
              <h3>第13条（権利義務の譲渡の禁止）</h3>
            </div>
            <div class="row">
              <div class="col">ユーザーは，本協会の書面による事前の承諾なく，利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し，または担保に供することはできません。</div>
            </div>
            <div class="row mt-2">
              <h3>第14条（準拠法・裁判管轄）</h3>
            </div>
            <div class="row">
              <ol class="li-number">
                <li value="30">
                  本規約の解釈にあたっては，日本法を準拠法とします。
                </li>
                <li>本システムに関して紛争が生じた場合には，本協会の本店所在地を管轄する裁判所を専属的合意管轄とします。</li>
              </ol>
            </div>
            <div class="row">
              <div class="col-3 col-md-1" style="position:absolute;right:0px;">以上</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TermsOfService",
  mounted() {
    window.scrollTo({top: 0, behavior: "auto"})
  }
}
</script>

<style lang="scss" scoped>
.eol {
  text-align: right;
  padding-right: 35px;
}

.terms-of-service-container {
  padding-left: 0;
  padding-right: 0;
  color: #333333;
}

ul {
  padding-left: 1rem;
}

li {
  margin-top: 5px;
}

.li-number {
  list-style-type: decimal;
  padding-left: 50px;
}

.li-latin {
  list-style-type: lower-latin;
  padding-left: 50px;
}

@media only screen and (max-width: 768px) {
  .li-number {
    padding-left: 30px;
  }

  .li-latin {
    padding-left: 30px;
  }
}

@media only screen and (max-width: 576px) {
  .li-number {
    padding-left: 25px;
  }

  .li-latin {
    padding-left: 10px;
  }
}

.update-btn-div {
  margin-bottom: 20px;
}

@media only screen and (max-width: 768px) {

  /*ul {
    padding-left: 0.5rem;
  }

  li p {
    line-height: 20px;
  }*/

}

@media only screen and (max-width: 576px) {

  .card {
    border: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 0;
  }

  .pt-terms-title {
    padding-top: 0.5rem;
  }

  .terms-of-service-container {
    margin-top: -50px;
    font-size: 13px;
  }
}
</style>